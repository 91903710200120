<template>
  <layout
    style="background: #f5f5f5;"
    :title="$route.name"
    :options="options"
    :rotas="rotas"
  >
    <div
      id="status_caixa"
      class="expande-horizontal column"
      style="min-height: 90vh; max-height: 95vh;"
    >
      <v-flex xs12>
        <div
          v-if="get_caixa.status_atual === 'fechado'"
          class="expande-horizontal centraliza"
          style="height: 100%;"
        >
          <v-list color="transparent" nav>
            <v-list-item
              style="border: 1px solid #fff; background: #fff;"
              class="item-list"
            >
              <v-list-item-content>
                <v-list-item-title class="fonte">
                  Caixa {{ get_caixa.status_atual }}
                </v-list-item-title>
                <v-list-item-subtitle class="fonte">
                  Clique em abrir ao lado
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  small
                  class="white--text"
                  color="green"
                  @click.prevent="abre_modal_abertura_caixa"
                  v-if="get_caixa.status_atual === 'fechado'"
                >
                  Abrir
                </v-btn>

                <v-btn
                  small
                  color="red"
                  text
                  @click.prevent="abre_modal_fechamento_caixa"
                  v-if="get_caixa.status_atual === 'aberto'"
                >
                  Fechar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
        <v-card
          v-else
          class="pa-3 expande-horizontal wrap"
          style="min-height: 85vh;"
        >
          <v-flex class="pa-6" xs12 md6>
            <div class="expande-horizontal column">
              <v-flex class="pa-3" xs12>
                <span class="fonte">
                  Selecione o tipo de atendimento abaixo
                </span>
              </v-flex>
              <v-flex class="mb-3" xs12>
                <div class="expande-horizontal">
                  <v-btn
                    @click.prevent="setTypeBuy('balcao')"
                    :color="
                      get_compra.tipo === 'balcao' ? $theme.secondary : ''
                    "
                    :text="get_compra.tipo === 'balcao' ? false : true"
                    :dark="get_compra.tipo === 'balcao' ? true : false"
                    class="mx-3 fonte"
                    :class="{ 'fonte-bold': get_compra.tipo === 'balcao' }"
                    small
                    >Balcão</v-btn
                  >
                  <v-btn
                    @click.prevent="setTypeBuy('mesa')"
                    :color="get_compra.tipo === 'mesa' ? $theme.secondary : ''"
                    :text="get_compra.tipo === 'mesa' ? false : true"
                    :dark="get_compra.tipo === 'mesa' ? true : false"
                    class="mx-3 fonte"
                    :class="{ 'fonte-bold': get_compra.tipo === 'mesa' }"
                    small
                    >Mesa</v-btn
                  >
                  <v-btn
                    @click.prevent="setTypeBuy('delivery')"
                    :color="
                      get_compra.tipo === 'delivery' ? $theme.secondary : ''
                    "
                    :text="get_compra.tipo === 'delivery' ? false : true"
                    :dark="get_compra.tipo === 'delivery' ? true : false"
                    class="mx-3 fonte"
                    :class="{ 'fonte-bold': get_compra.tipo === 'delivery' }"
                    small
                    >Delivery</v-btn
                  >
                </div>
              </v-flex>
              <!-- Pesquisa -->
              <v-flex xs12>
                <div class="expande-horizontal">
                  <v-text-field
                    @keyup.enter.prevent="attachOpenModalAddProduct"
                    v-model="inputSearch"
                    label="Pesquisar item"
                    single-line
                    ref="searchField"
                    hide-details
                    class="mx-3 fonte"
                    outlined
                    dense
                    prepend-inner-icon="mdi-magnify"
                  ></v-text-field>
                </div>
              </v-flex>

              <!-- Categorias de Produtos -->
              <v-flex v-if="inputSearch === ''" class="pt-3 pb-3" xs12>
                <v-flex xs12 class="pa-3">
                  <span class="fonte fonte-bold grey--text mt-3 mb-3"
                    >Categorias</span
                  >
                </v-flex>
                <v-slide-group show-arrows>
                  <v-slide-item>
                    <v-btn
                      small
                      class="mx-2 fonte text-capitalize fonte-bold"
                      :input-value="allCategories"
                      active-class="orange darken-2 white--text"
                      depressed
                      rounded
                      @click.prevent="setCategory(false)"
                    >
                      Todos
                    </v-btn>
                  </v-slide-item>
                  <v-slide-item
                    v-for="categoria in get_produtos.docs"
                    v-show="
                      categoria.preco_multiplo &&
                        categoria.preco_multiplo.filter(
                          preco => preco.disponivel
                        ).length
                    "
                    :key="categoria._id"
                  >
                    <v-btn
                      small
                      class="mx-2 fonte text-capitalize fonte-bold"
                      :input-value="categoria._id === categoria_escolhida._id"
                      active-class="orange darken-2 white--text"
                      depressed
                      rounded
                      @click.prevent="setCategory(categoria)"
                    >
                      {{ categoria.nome }}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </v-flex>

              <v-flex class="pt-3" xs12>
                <div class="expande-horizontal wrap">
                  <v-flex xs12 class="pa-3 pt-0">
                    <span
                      v-if="inputSearch === ''"
                      class="fonte fonte-bold grey--text mt-3 mb-3"
                      >Produtos</span
                    >
                    <span
                      v-if="inputSearch !== ''"
                      class="fonte fonte-bold grey--text mt-3 mb-3"
                      >Produtos Encontrados -
                      {{ productSearchList.length }} encontrados</span
                    >
                  </v-flex>
                  <v-flex
                    class="pa-3"
                    :key="produto.nome"
                    v-show="produto.disponivel && inputSearch === ''"
                    v-for="produto in categoria_escolhida.preco_multiplo"
                    xs3
                  >
                    <v-card
                      @click.prevent="openModalAddProduct(produto)"
                      color="#F0F0F0"
                      min-height="70"
                      class="mx-auto animate__animated animate__flipInY"
                      max-width="344"
                      flat
                    >
                      <v-img
                        height="60"
                        v-if="produto.imagens"
                        :src="produto.imagens[0]"
                      ></v-img>

                      <div
                        class="expande-horizontal centraliza"
                        style="min-height: 60px;"
                      >
                        <h6 class="text-center fonte fonte-bold">
                          {{ produto.nome }}
                        </h6>
                      </div>
                      <h4
                        class="text-center fonte fonte-bold"
                        style="color: #DF693E"
                      >
                        {{ $helper.formataSaldo(produto.preco_com_lp) }}
                      </h4>

                      <div class="expande-horizontal centraliza">
                        <v-btn
                          @click.prevent="openModalAddProduct(produto)"
                          icon
                          small
                        >
                          <v-icon size="18" color="#17B51C">
                            mdi-plus-circle-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-flex>
                  <v-flex
                    class="pa-3"
                    :key="produto.nome"
                    v-show="inputSearch !== ''"
                    v-for="produto in productSearchList"
                    xs3
                  >
                    <v-card
                      @click.prevent="openModalAddProduct(produto)"
                      color="#F0F0F0"
                      min-height="70"
                      class="mx-auto animate__animated animate__flipInY"
                      max-width="344"
                      flat
                    >
                      <v-img
                        height="60"
                        v-if="produto.imagens"
                        :src="produto.imagens[0]"
                      ></v-img>

                      <div
                        class="expande-horizontal centraliza"
                        style="min-height: 60px;"
                      >
                        <h6 class="text-center fonte fonte-bold">
                          {{ produto.nome }}
                        </h6>
                      </div>
                      <h4
                        class="text-center fonte fonte-bold"
                        style="color: #DF693E"
                      >
                        {{ $helper.formataSaldo(produto.preco_com_lp) }}
                      </h4>

                      <div class="expande-horizontal centraliza">
                        <v-btn
                          @click.prevent="openModalAddProduct(produto)"
                          icon
                          small
                        >
                          <v-icon size="18" color="#17B51C">
                            mdi-plus-circle-outline
                          </v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                  </v-flex>
                  <div
                    class="expande-horizontal wrap"
                    style="max-height: 270px; overflow: auto;"
                  >
                    <v-flex
                      class="pa-3"
                      :key="categoria.nome"
                      v-show="allCategories && inputSearch === ''"
                      v-for="categoria in get_produtos.docs"
                      xs12
                    >
                      <v-card
                        style="border: 1px solid #f0f0f0;"
                        class="expande-horizontal wrap elevation-0"
                      >
                        <h3 class="fonte orange--text pa-3">
                          {{ categoria.nome }}
                        </h3>
                        <div class="expande-horizontal wrap">
                          <v-flex
                            class="pa-3"
                            :key="produto.nome"
                            v-for="produto in categoria.preco_multiplo"
                            v-show="produto.disponivel"
                            xs6
                            md3
                          >
                            <v-card
                              @click.prevent="openModalAddProduct(produto)"
                              color="#F0F0F0"
                              min-height="70"
                              class="mx-auto animate__animated animate__flipInY"
                              max-width="344"
                              flat
                            >
                              <v-img
                                height="60"
                                v-if="produto.imagens"
                                :src="produto.imagens[0]"
                              ></v-img>

                              <div
                                class="expande-horizontal centraliza"
                                style="min-height: 60px;"
                              >
                                <h6 class="text-center fonte fonte-bold">
                                  {{ produto.nome }}
                                </h6>
                              </div>
                              <h4
                                class="text-center fonte fonte-bold"
                                style="color: #DF693E"
                              >
                                {{ $helper.formataSaldo(produto.preco_com_lp) }}
                              </h4>

                              <div class="expande-horizontal centraliza">
                                <v-btn
                                  @click.prevent="openModalAddProduct(produto)"
                                  icon
                                  small
                                >
                                  <v-icon size="18" color="#17B51C">
                                    mdi-plus-circle-outline
                                  </v-icon>
                                </v-btn>
                              </div>
                            </v-card>
                          </v-flex>
                        </div>
                      </v-card>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </div>
          </v-flex>
          <v-flex class="pa-6" xs12 md6>
            <v-card flat>
              <v-flex xs12>
                <div
                  class="expande-horizontal wrap"
                  style="background: #F0F0F0; min-height: 70vh;"
                >
                  <div class="expande-horizontal pa-6">
                    <h4 class="fonte text-uppercase">{{ get_compra.tipo }}</h4>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      color="red"
                      class="text-capitalize fonte fonte-bold"
                      outlined
                      @click.prevent="limparComanda({ confirmation: false })"
                    >
                      Limpar Comanda
                    </v-btn>
                  </div>
                  <div class="expande-horizontal">
                    <v-flex class="px-6" xs12>
                      <v-autocomplete
                        outlined
                        class="fonte"
                        dense
                        prepend-inner-icon="mdi-magnify"
                        label="Pesquisar Cliente"
                        :items="get_clientes.docs"
                        item-text="nome"
                        return-object
                        v-model="get_compra.cliente"
                      ></v-autocomplete>
                    </v-flex>
                  </div>
                  <!-- produtos adicionados -->
                  <v-flex xs12>
                    <div class="expande-horizontal pb-6">
                      <v-flex v-if="get_compra.produtos.length" xs12>
                        <v-simple-table
                          dark
                          style="witdh: 100%; height: 200px; overflow: auto; background: #f0f0f0;"
                          dense
                        >
                          <template v-slot:default>
                            <thead style="background: #EBEBEB; height: 40px;">
                              <tr
                                style="background: #EBEBEB; border: 1px solid #f0f0f0;"
                              >
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Código
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Item
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Quant
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686; border-right: 1px solid #D2D4CF;"
                                >
                                  Preço
                                </td>
                                <td
                                  class="font-weight-bold fonte"
                                  style="color: #878686;"
                                >
                                  Subtotal
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <template
                                style="border-top: 1px solid #D2D4CF;"
                                v-for="(item, i) in get_compra.produtos"
                              >
                                <tr
                                  class="mt-3"
                                  style="background: #f0f0f0; border: 1px solid #f0f0f0;"
                                  :key="(i + 1) / 3"
                                >
                                  <td
                                    @click.prevent="abre_modal_view_caixa(item)"
                                    style="color: #878686; width: 10px; border-top: 1px dashed #D2D4CF;"
                                    class="fonte fonte-bold"
                                  >
                                    # {{ item.codigo ? item.codigo : "N/A" }}
                                  </td>
                                  <td
                                    class="fonte fonte-bold"
                                    style="color: #494949; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{ item.nome }}
                                  </td>
                                  <td
                                    class="fonte fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{ item.quantity }}x
                                  </td>
                                  <td
                                    class="fonte green--text fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{
                                      $helper.formataSaldo(item.preco_com_lp)
                                    }}
                                  </td>
                                  <td
                                    class="fonte green--text fonte-bold"
                                    style="color: #878686; border-top: 1px dashed #D2D4CF;"
                                    @click.prevent="abre_modal_view_caixa(item)"
                                  >
                                    {{
                                      $helper.formataSaldo(item.seller_price)
                                    }}
                                  </td>
                                </tr>
                                <template
                                  v-for="(adicional, i) in item.adicionais"
                                >
                                  <tr
                                    style="background: #f0f0f0; border: none;"
                                    v-for="(item, index) in adicional.itens"
                                    :key="(index + 1) / 4"
                                    v-show="
                                      item.quantidade_adicionada &&
                                        item.quantidade_adicionada >= 1
                                    "
                                  >
                                    <td
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                      style="color: #878686; width: 10px"
                                      class="fonte fonte-micro"
                                    >
                                      <!-- # {{ item.codigo ? item.codigo : "N/A" }} -->
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686;"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ item.descricao_personalizada }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ item.quantidade_adicionada }}x
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{ $helper.formataSaldo(item.preco) }}
                                    </td>
                                    <td
                                      class="fonte fonte-micro"
                                      style="color: #878686"
                                      @click.prevent="
                                        abre_modal_view_caixa(item)
                                      "
                                    >
                                      {{
                                        $helper.formataSaldo(
                                          item.quantidade_adicionada *
                                            parseFloat(item.preco)
                                        )
                                      }}
                                    </td>
                                  </tr>
                                </template>
                                <tr
                                  light
                                  v-show="item.obs"
                                  style="background: #f0f0f0; border-bottom: 3px dashed #363636;"
                                  :key="(i + 1) / 6"
                                  xs12
                                >
                                  <td
                                    class="fonte fonte-micro"
                                    style="color: #878686"
                                  >
                                    Obs:
                                  </td>
                                  <td
                                    class="fonte fonte-micro"
                                    style="color: #878686"
                                  >
                                    {{ item.obs }}
                                  </td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </template>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-flex>
                      <div
                        v-else
                        style="height: 200px;"
                        class="expande-horizontal centraliza pb-6 column"
                      >
                        <img width="60" src="img/logo_mini.svg" class="mb-3" />

                        <!-- <v-icon color="grey" size="64">mdi-cart-check</v-icon> -->
                        <h4 class="fonte grey--text">
                          Seu caixa está livre no momento
                        </h4>
                      </div>
                    </div>
                  </v-flex>

                  <div class="expande-horizontal wrap">
                    <v-flex class="px-6" xs12>
                      <span class="fonte fonte-bold grey--text">Subtotal:</span>
                      <span class="fonte fonte-bold">{{
                        $helper.formataSaldo(get_compra.subtotal || 0)
                      }}</span>
                      <span class="fonte ml-2 fonte-micro orange--text"
                        >{{ get_compra.produtos.length }} itens</span
                      >
                    </v-flex>
                    <v-flex class="py-3" xs12>
                      <div class="expande-horizontal">
                        <v-flex class="px-3" xs6>
                          <v-btn
                            outlined
                            bigger
                            @click.prevent="$refs.modalAcrescimo.open()"
                            color="grey"
                            class="fonte text-capitalize"
                            block
                            text
                          >
                            <v-icon :color="$theme.primary"
                              >mdi-plus-circle-outline</v-icon
                            >
                            <h4>
                              Acréscimo
                              {{
                                $helper.formataSaldo(get_compra.acrescimo || 0)
                              }}
                            </h4>
                          </v-btn>
                        </v-flex>
                        <v-flex class="px-3" xs6>
                          <v-btn
                            outlined
                            bigger
                            @click.prevent="$refs.modalDesconto.open()"
                            color="grey"
                            class="fonte text-capitalize"
                            block
                            text
                          >
                            <v-icon :color="$theme.primary"
                              >mdi-minus-circle-outline</v-icon
                            >
                            <h4>
                              Desconto
                              {{
                                $helper.formataSaldo(get_compra.desconto || 0)
                              }}
                            </h4>
                          </v-btn>
                        </v-flex>
                      </div>
                      <v-flex class="px-6 mt-3" xs12>
                        <span class="fonte fonte-bold grey--text">Total:</span>
                        <span class="fonte fonte-bold">{{
                          $helper.formataSaldo(get_compra.preco_total || 0)
                        }}</span>
                        <span class="fonte ml-2 fonte-micro orange--text"
                          >{{ get_compra.produtos.length }} itens</span
                        >
                      </v-flex>
                    </v-flex>
                    <v-flex xs12>
                      <div class="expande-horizontal">
                        <v-flex class="pa-3 pt-0" xs6>
                          <v-card
                            height="60"
                            style="border: 2px solid #17B51C;"
                            hover
                            @click.prevent="salvarCompra"
                          >
                            <v-flex xs12>
                              <div
                                class="fonte fonte-bold expande-horizontal centraliza"
                                style="height: 60px;"
                              >
                                SALVAR COMANDA
                              </div>
                            </v-flex>
                          </v-card>
                        </v-flex>
                        <v-flex class="pa-3 pt-0" xs6>
                          <v-card
                            height="60"
                            hover
                            :color="
                              get_compra.preco_total ? $theme.secondary : 'grey'
                            "
                            @click.prevent="initPayment"
                            dark
                          >
                            <v-flex xs12>
                              <div
                                class="fonte column fonte-bold expande-horizontal centraliza"
                                style="height: 60px;"
                              >
                                PAGAR
                                <span
                                  class="fonte fonte-micro"
                                  style="font-weight: 300"
                                >
                                  (
                                  {{
                                    $helper.formataSaldo(
                                      get_compra.preco_total || 0
                                    )
                                  }}
                                  )
                                </span>
                              </div>
                            </v-flex>
                          </v-card>
                        </v-flex>
                      </div>
                    </v-flex>
                  </div>
                </div>
              </v-flex>
            </v-card>
          </v-flex>
        </v-card>
      </v-flex>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      filename="receipt"
      :manual-pagination="true"
      pdf-format="legal"
      :pdf-quality="10"
      pdf-content-width="80"
      pdf-orientation="portrait"
      @progress="() => {}"
      @hasStartedGeneration="() => {}"
      @hasDownloaded="() => {}"
      ref="html2Pdf"
    >
      <div slot="pdf-content">
        <section class="pdf-item">
          <div class="expande-horizontal column wrap">
            <div class="fonte expande-horizontal centraliza logo-receipt">
              <img
                v-if="
                  getLoggedUser.tenant[0].photos &&
                    getLoggedUser.tenant[0].photos.length
                "
                :src="getLoggedUser.tenant[0].photos[0]"
                alt="logo"
                style="height: 100px;"
              />
              <img v-else src="img/logo_minni.svg" style="height: 60px" alt="" />
            </div>
            <div class="expande-horizontal column centraliza address-establishment-receipt">
              <span class="fonte-micro">
                {{ getLoggedUser.tenant[0].defaultAddress }}
              </span>
              <strong class="mt-2 mb-2 fonte-mini">
                *** NÃO É DOCUMENTO FISCAL ***
              </strong>
              <span class="fonte-micro">
                Realizado {{ moment(lastSell.createdAt).format('DD/MM/YYYY HH:mm') }}
              </span>
            </div>
            <div class="expande-horizontal centraliza id-receipt">
              <h3>ID Pedido: #{{ lastSell.id }}</h3>
            </div>
          </div>
        </section>
      </div>
    </vue-html2pdf>
    <ModalViewAbertura />
    <ModalViewFechamento />
    <ModalAcrescimo ref="modalAcrescimo" />
    <ModalDesconto ref="modalDesconto" />
    <ModalFechamento :sendToPreparing="finishSell" ref="modalFechamento" />
    <ModalAddProduct
      :addProductToCart="addProductToCart"
      ref="modalAddProduct"
    />
  </layout>
</template>

<script>
import VisualizacaoEmCard from "../components/VisualizacaoEmCard.vue";
import VisualizacaoEmLista from "../components/VisualizacaoEmLista.vue";
import ModalViewAbertura from "../components/modalViewAbertura.vue";
import ModalViewFechamento from "../components/modalViewFechamento.vue";
import ModalAcrescimo from "../components/Acrescimo.vue";
import ModalDesconto from "../components/Desconto.vue";
import ModalFechamento from "../components/ModalFechamento.vue";
import ModalAddProduct from "./components/ModalAddProduct.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/main.js";

export default {
  data() {
    return {
      moment: moment,
      allCategories: true,
      card: false,
      modalAcrescimo: false,
      modalDesconto: false,
      searchCliente: "",
      inputSearch: "",
      productSearchList: [],
      categoria_escolhida: {
        preco_multiplo: []
      },
      rotas: [
        {
          name: "Inicio",
          path: ""
        },
        {
          name: "atendimento",
          path: "/atendimento"
        }
      ],
      options: [
        {
          icon: "mdi-update",
          nome: "Atualizar",
          action: () => this.listar_caixas()
        },
        {
          icon: "mdi-close",
          nome: "Fechar Caixa",
          action: () => this.abre_modal_fechamento_caixa()
        }
      ],
      lastSell: {
        cliente: {},
        produtos: []
      }
    };
  },
  components: {
    VisualizacaoEmCard,
    VisualizacaoEmLista,
    ModalViewAbertura,
    ModalViewFechamento,
    ModalDesconto,
    ModalAcrescimo,
    ModalFechamento,
    ModalAddProduct
  },
  watch: {
    inputSearch(val) {
      if (val) {
        this.search();
      } else {
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    }
  },
  computed: {
    ...mapGetters([
      "get_caixa",
      "get_produtos",
      "get_produto",
      "get_compra",
      "get_produtocategorias",
      "get_produtocategorias_filtros",
      "get_produtocategoria",
      "get_produtos_filtros",
      "get_caixas",
      "get_clientes",
      "get_clientes_filtros",
      "get_cliente",
      "get_caixas_filtros",
      "getLoggedUser"
    ])
  },
  methods: {
    attachOpenModalAddProduct() {
      if (this.productSearchList.length) {
        this.$refs.modalAddProduct.open(this.productSearchList[0]);
      }
    },
    openModalAddProduct(produto) {
      this.$refs.modalAddProduct.open(produto);
    },
    setTypeBuy(tipo) {
      this.get_compra.tipo = "";
      this.get_compra.tipo = tipo;
      this.$forceUpdate();
    },
    setCategory(categoria) {
      if (categoria) {
        this.categoria_escolhida = categoria;
        this.allCategories = false;
      } else {
        this.allCategories = true;
        this.categoria_escolhida = {
          preco_multiplo: []
        };
      }
    },
    ...mapActions([
      "createGlobalMessage",
      "add_produto_a_compra",
      "salvar_compra",
      "finalizar_compra",
      "abre_modal_view_caixa",
      "abre_modal_abertura_caixa",
      "abre_modal_fechamento_caixa",
      "calcula_valor_total_da_compra",
      "listar_caixas",
      "listar_clientes",
      "listar_produtos",
      "listar_produtocategorias",
      "remove_produto_da_compra",
      "createConfirmAction"
    ]),
    finishSell() {
      this.finalizar_compra();
    },
    printReceipt(compra) {
      this.lastSell = compra;
      this.$refs.html2Pdf.generatePdf();
    },
    search() {
      if (!this.inputSearch) return;

      this.productSearchList = [];
      this.get_produtos.docs.map(product => {
        product.preco_multiplo.map(preco => {
          if (preco.disponivel) {
            if (
              preco.nome.toLowerCase().includes(this.inputSearch.toLowerCase())
            ) {
              this.productSearchList.push(preco);
            } else if (
              product.code &&
              product.code
                .toLowerCase()
                .indexOf(this.inputSearch.toLowerCase()) > -1
            )
              return true;
          }
        });
      });
    },
    setModalAddProduto(produto) {
      this.$store.commit("set_produto", produto);
      this.modalStageProduct = true;
    },
    addProductToCart(product) {
      this.get_compra.produtos.push(product);
      this.$refs.modalAddProduct.close();
      this.createGlobalMessage({
        type: "success",
        message: "Produto adicionado com sucesso!",
        timeout: 6000
      });
      this.calcula_valor_total_da_compra();
    },
    initPayment() {
      //validations here pls
      if (this.get_compra.produtos.length) {
        if (!this.$refs.modalFechamento.modal) {
          this.$refs.modalFechamento.open();
        }
      } else {
        this.createGlobalMessage({
          type: "error",
          message: "Não é possível efetuar uma compra sem produtos!",
          timeout: 6000
        });
      }
    },
    limparComanda(item) {
      if (item && item.confirmation) {
        this.$store.commit("set_compra", {
          cliente: {},
          produtos: [],
          tipo: "balcao",
          subtotal: 0
        });
        this.$forceUpdate();
      } else {
        this.createConfirmAction({
          message:
            "Deseja limpar a comanda? Isso irá apagar todos os produtos da comanda e zerar o valor.",
          icone: "mdi-close-circle",
          isScoped: true,
          action: () => this.limparComanda({ confirmation: true }),
          action_value: "ok"
        });
      }
    },
    salvarCompra() {
      this.salvar_compra();
    }
  },
  created() {
    this.get_caixas_filtros.funcionario = this.getLoggedUser;
    this.get_caixas_filtros.meu_caixa = true;
    this.get_caixas_filtros.filter = true;
    this.listar_caixas();
    this.listar_produtos();
    // this.listar_produtocategorias();
    this.listar_clientes();
  },
  mounted() {
    EventBus.$on("escape", () => {
      this.$refs.searchField.focus();
    });
    EventBus.$on("ArrowRight", () => {
      this.initPayment();
    });
    EventBus.$on("print_compra", compra => this.printReceipt(compra));
  }
};
</script>

<style scoped>
.item_caixa {
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 6px;
  background: #333;
  color: #f2f2f2;
}

.caixa_font_color {
  color: #fff;
}

.caixa-status--fechado {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: rgb(236, 82, 82);
}
.caixa-status--aberto {
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 6px;
  background: green;
}

.activeCategory {
  background-color: #3eb4df;
}
</style>
