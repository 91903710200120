<template>
  <v-dialog
    transition="slide-x-transition"
    style="overflow: hidden;"
    width="800"
    height="700"
    v-model="opened"
  >
    <v-card
      :style="`overflow: ${$vuetify.breakpoint.smAndDown ? 'auto' : 'hidden'};`"
      height="500"
      width="800"
    >
      <v-flex xs12>
        <div class="expande-horizontal wrap">
          <v-flex xs12 md6>
            <div class="expande-horizontal">
              <v-carousel
                cycle
                :height="$vuetify.breakpoint.smAndDown ? '400' : '512'"
                width="100%"
                class="pa-0 ma-0"
                hide-delimiter-background
                show-arrows-on-hover
              >
                <v-carousel-item v-for="(slide, i) in product.imagens" :key="i">
                  <img style="height: 100%;" :src="slide" />
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-flex>
          <v-flex class="pt-3" xs12 md6>
            <div class="expande-horizontal">
              <v-flex xs4>
                <div
                  class="fonte column expande-horizontal pa-6 pr-0 font-color"
                >
                  <h3 class="font-color--secondary">
                    {{ $helper.formataSaldo(product.seller_price) }}
                  </h3>
                  <span class="grey--text fonte-micro">Preço final</span>
                </div>
              </v-flex>
              <v-flex class="pl-0 pt-2 pr-6" xs8>
                <v-list dark color="transparent">
                  <v-list-item
                    class="pa-0 ma-0 pl-3 fonte item-list"
                    style="background: #e1673c"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="fonte-bold">{{
                        product.nome
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="fonte-micro white--text">
                        {{ $helper.formataSaldo(product.preco_com_lp) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn @click.prevent="close" icon class="mr-4">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-flex>
            </div>
            <v-flex
              xs12
              class="pl-6 pr-6 pt-3 adicionals"
              style="height: 358px; overflow: auto;"
            >
              <div class="expande-horizontal">
                <h4 class="grey--text fonte pt-0 pb-3">adicionais:</h4>
              </div>
              <div class="expande-horizontal wrap">
                <v-flex xs12>
                  <v-list
                    class="pa-0 ma-0"
                    v-for="(adicional, index) in product.adicionais"
                    :id="`aditional_${index}`"
                    :key="adicional.nome_grupo"
                  >
                    <v-list-item class="pa-0 ma-0 pl-3 fonte item-list">
                      <v-list-item-content>
                        <v-list-item-title class="pb-1 fonte-bold">
                          {{ adicional.nome_grupo }}
                          <v-btn
                            outlined
                            class="ml-3"
                            :color="
                              adicional.obrigatorio ? 'red darken-2' : 'grey'
                            "
                            x-small
                            dark
                            >{{
                              adicional.obrigatorio ? "obrigatório" : "opcional"
                            }}</v-btn
                          >
                        </v-list-item-title>
                        <v-list-item-subtitle class="fonte-micro">
                          {{ adicional.quantidade_adicionada || 0 }} de
                          {{ adicional.maximo }} itens adicionados
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <template v-for="(item_adicional, i) in adicional.itens">
                      <v-list-item class="fonte" :key="item_adicional.nome">
                        <v-list-item-content>
                          <v-list-item-title class="fonte">
                            {{
                              item_adicional.descricao_personalizada
                                ? item_adicional.descricao_personalizada
                                : item_adicional.nome
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            class="fonte fonte-bold green--text"
                          >
                            + {{ $helper.formataSaldo(item_adicional.preco) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <div class="expande-horizontal">
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada > 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-minus-circle-outline</v-icon>
                            </v-btn>
                            <v-btn
                              @click.prevent="removeAditional(i, index)"
                              v-if="
                                item_adicional &&
                                  item_adicional.quantidade_adicionada === 1
                              "
                              icon
                              x-small
                            >
                              <v-icon>mdi-delete-circle-outline</v-icon>
                            </v-btn>
                            <h5 class="mx-2 fonte fonte-bold">
                              {{ item_adicional.quantidade_adicionada || 0 }}
                            </h5>
                            <v-btn @click="addAditional(i, index)" icon x-small>
                              <v-icon color="green"
                                >mdi-plus-circle-outline</v-icon
                              >
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-flex>
                <v-flex class="pt-6" xs12>
                  <v-textarea
                    filled
                    height="100px"
                    @keyup.enter.prevent="addProduct"
                    hint="Pressione enter para adicionar rapidamente"
                    v-model="product.obs"
                    label="Observações"
                  ></v-textarea>
                </v-flex>
              </div>
            </v-flex>
            <div
              class="pl-6 expande-horizontal"
              style="border-top: 1px dashed #e5e5e5;"
            >
              <v-btn
                @click.prevent="removeProductQtd"
                v-if="product.quantity > 1"
                icon
                small
              >
                <v-icon>mdi-minus-circle-outline</v-icon>
              </v-btn>
              <v-btn
                @click.prevent="removeProductQtd"
                v-if="product.quantity === 1"
                icon
                small
              >
                <v-icon>mdi-delete-circle-outline</v-icon>
              </v-btn>
              <h3 class="mx-2 fonte fonte-bold">
                {{ product.quantity }}
              </h3>
              <v-btn @click="addProductQtd" icon small>
                <v-icon color="green">mdi-plus-circle-outline</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                tile
                @click.prevent="addProduct"
                :color="$theme.secondary"
                class="fonte fonte-bold"
                dark
              >
                Adicionar {{ $helper.formataSaldo(product.seller_price) }}
              </v-btn>
            </div>
          </v-flex>
        </div>
      </v-flex>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    addProductToCart: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      opened: false,
      product: {
        quantidade: 1,
        imagens: []
      },
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4"
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"]
    };
  },
  methods: {
    ...mapActions(["createGlobalMessage"]),
    open(product) {
      this.opened = true;
      this.product = { ...product, quantity: 1 };
      this.product.seller_price = product.preco_com_lp;
    },
    close() {
      this.opened = false;
      this.product = { quantity: 1 };
    },
    addAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada < parseInt(item_adicional.maximo)
      ) {
        item_adicional.quantidade_adicionada++;
        item_adicional.itens[index_item_adicional].quantidade_adicionada
          ? item_adicional.itens[index_item_adicional].quantidade_adicionada++
          : (item_adicional.itens[
              index_item_adicional
            ].quantidade_adicionada = 1);
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === parseInt(item_adicional.maximo)
      ) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: "Opa, quantidade máxima atingida!",
          timeout: 6000
        });
      } else if (!item_adicional.quantidade_adicionada) {
        item_adicional.quantidade_adicionada = 1;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 1;
      }

      this.product.adicionais[index_adicional] = item_adicional;
      this.$forceUpdate();
      this.processFinalPrice();
    },
    removeAditional(index_item_adicional, index_adicional) {
      let item_adicional = this.product.adicionais[index_adicional];
      if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada > 1
      ) {
        item_adicional.quantidade_adicionada--;
        item_adicional.itens[index_item_adicional].quantidade_adicionada--;
      } else if (
        item_adicional.quantidade_adicionada &&
        item_adicional.quantidade_adicionada === 1
      ) {
        item_adicional.quantidade_adicionada = 0;
        item_adicional.itens[index_item_adicional].quantidade_adicionada = 0;
      }
      this.product.adicionais[index_adicional] = item_adicional;
      this.processFinalPrice();
    },
    // thiss process product.seller_price
    processFinalPrice() {
      const aditionals = this.product.adicionais || [];
      let initial_price = parseFloat(this.product.preco_com_lp);
      let aditional_total_price = 0;
      aditionals.map(adicional => {
        adicional.itens.map(item => {
          if (item.quantidade_adicionada) {
            aditional_total_price +=
              parseInt(item.quantidade_adicionada) * parseFloat(item.preco);
          }
        });
      });
      this.product.seller_price =
        this.product.quantity * (initial_price + aditional_total_price);
      this.product.aditional_total_price = aditional_total_price;
      this.$forceUpdate();
    },
    addProduct() {
      const product = this.product;
      let ok = true;
      let ok_group = "";
      if (product.adicionais && product.adicionais.length) {
        product.adicionais.map(aditional => {
          if (aditional.obrigatorio) {
            if (!aditional.quantidade_adicionada) {
              ok = false;
              ok_group = aditional.nome_grupo;
            } else if (
              aditional.quantidade_adicionada &&
              aditional.quantidade_adicionada < 1
            ) {
              ok = false;
              ok_group = aditional.nome_grupo;
            }
          }
        });
      }
      if (!ok) {
        this.createGlobalMessage({
          type: "info",
          icon: "mdi-info",
          message: `Opa, o adicional de ${ok_group} é obrigatório!`,
          timeout: 6000
        });
      } else {
        this.addProductToCart(product);
      }
    },
    addProductQtd() {
      this.product.quantity++;
      this.processFinalPrice();
    },
    removeProductQtd() {
      if (this.product.quantity > 1) {
        this.product.quantity--;
      }
      this.processFinalPrice();
    }
  }
};
</script>

<style scoped>
.item-list {
  background: #f0f0f0;
  border-radius: 6px;
}
.adicionals::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.adicionals::-webkit-scrollbar-track {
  background: rgb(226, 226, 226); /* color of the tracking area */
  border-radius: 6px;
}

.adicionals::-webkit-scrollbar-thumb {
  background-color: #3eb4df; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}
</style>
